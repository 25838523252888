import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import Toptitle from "../components/toptitle"
import styled from "styled-components"

const Profile = () => (
  <Layout>
    <SEO title="アートマテリアルについて" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <Toptitle>Profile</Toptitle>
        <div className="pt-32 lg:mt-16 lg:pt-0">
          <h1 className="font-mincho text-3xl text-primary">
            アートマテリアルについて
          </h1>
        </div>
        <div className="mt-16 md:flex">
          <div className="my-auto mx-8 lg:w-1/2">
            <h2 className="font-mincho text-2xl text-primary">
              使用目的に応じた不燃内装材の提案
            </h2>
            <p className="mt-4 font-gothic leading-loose text-neutral">
              お客様からのご要望を元に、適切な加工方法や材料のご提案だけではなく、小ロットから大量の製造・予算・納期のご相談にも柔軟にお応えしております。
            </p>
          </div>
          <MobileWrapper>
            <StaticImage
              src="../images/profile/products.jpg"
              alt="アートマテリアルの製品群"
            />
          </MobileWrapper>
        </div>
        <div className="my-16 w-full bg-secondery py-16 px-8">
          <div className="lg:w-2/3">
            <h2 className="font-mincho text-2xl text-primary">
              予算・納期に応じた柔軟な対応
            </h2>
            <p className="mt-4 font-gothic leading-loose text-neutral">
              予算や納期が厳しい場合でも、ぜひ一度ご相談をください。
              <br />
              コストダウンや納期短縮を可能とする製作方法や代替案など、これまで15年以上培ってきた経験とノウハウをもってご対応いたします。
            </p>
          </div>
        </div>
        <div className="mt-8 md:flex">
          <MobileWrapper>
            <StaticImage
              src="../images/profile/slim.png"
              alt="軽量化処理が行われたモールディング"
            />
          </MobileWrapper>
          <div className="my-auto mx-8 lg:w-1/2">
            <h2 className="font-mincho text-2xl text-primary">
              施工性やコストダウンの追求
            </h2>
            <p className="mt-4 font-gothic leading-loose text-neutral">
              いただいた図面通りに製作することは当然ですが、より良い形状に、より施工性が高まる軽量化や分割、よりコストダウンが叶うように、など、お客様にメリットがあることは必ずご提案いたします。
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

const MobileWrapper = styled.div`
  @media screen and (max-width: 1023px) {
    display: none;
  }
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
`

export default Profile
